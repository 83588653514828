import slick from 'slick-carousel/slick/slick.js';
import 'slick-carousel/slick/slick.css';

global.slick = slick;
jQuery(function () {
    window.addEventListener(
        "mouseover",
        function onFirstHover() {
            jQuery("html").addClass("has-mouse");
            window.removeEventListener("mouseover", onFirstHover, false);
        },
        false
    );

    // Texte de la page d'accueil
    jQuery('body').one('click', '.js-toggle-about', function (ev) {
        jQuery('.about__textmore').slideToggle('fast');
        jQuery('.about__toggleimg').toggleClass('open');
        ev.preventDefault();
    });

    jQuery('body').on('click', '.footer-menu__open-menu', function () {
        jQuery('.footer-menu__content').addClass('show');
    });

    jQuery('body').on('click', '.footer-menu__close-button', function () {
        jQuery('.footer-menu__content').removeClass('show');
    });

    jQuery('body').on('click', '.see-more', function (e) {
        e.preventDefault();
        var toOpen = jQuery(this).data('target');
        jQuery('#lawyer-details-' + toOpen).slideToggle();
    });

    function loadLawyers(url) {
        jQuery('#js-annuaire-container').load(
            addtpl(url, 'liste_avocats') + ' #js-annuaire-container>*',
            function () {
                if (jQuery('.lawyer-details').length > 1) {
                    jQuery('.lawyer-details').hide();
                }
            }
        );
    }

    function updateForm(form) {
        const url = new URL(window.location);
        url.hash = jQuery(form).serialize();
        window.history.pushState({}, '', url);
        loadLawyers(jQuery('#js-annuaire-container').data('url') + '?' + jQuery(form).serialize());
    }

    if (jQuery('.lawyer-details').length > 1) {
        jQuery('.lawyer-details').hide();
    }
    jQuery('body').on('click', '.list-annuaire input[type=submit]', function (ev) {
        updateForm(this.form)
        ev.preventDefault()
    });
    jQuery('body').on('change', '.list-annuaire select', function (ev) {
        updateForm(this.form)
        ev.preventDefault()
    });

    jQuery('body').on('click', '.list-annuaire .pagination a[href]', function () {
        loadLawyers(this.href);
        jQuery(window).scrollTop(0);
        return false;
    });

    window.onhashchange = function () {
        loadLawyers(jQuery('#js-annuaire-container').data('url') + '?' + window.location.hash.substring(1));
    }
});
