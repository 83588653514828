jQuery(function () {
  jQuery(".footer-map").hide();

  jQuery(document).on("click", "#btnmap", function (event) {
  

 
      jQuery.getScript(
        "https://unpkg.com/leaflet@1.8.0/dist/leaflet.js",
        function () {
          var mapDiv = jQuery("#map");
          jQuery("head").append(
            '<link rel="stylesheet" href="https://unpkg.com/leaflet@1.8.0/dist/leaflet.css" crossorigin="" />'
          );
          var lat = mapDiv.data("lat");
          var long = mapDiv.data("long");
          var poi = mapDiv.data("poi");

          var map = L.map("map", { zoomControl: false }).setView(
            [lat, long],
            12
          );
          var show = false;

          var poi = L.icon({
            iconUrl: poi,
            iconSize: [25, 34], // size of the icon
            iconAnchor: [12, 27], // point of the icon which will correspond to marker's location
          });

          L.tileLayer(
            "https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png",
            {
              maxZoom: 20,
              minZoom: 12,
              attribution: "",
            }
          ).addTo(map);

          L.marker([lat, long], { icon: poi }).addTo(map);
       
          jQuery(document).off("click", "#btnmap");
        }
      );
    
    jQuery(".footer-map").slideDown();
  });
});
