/*
 *
 * Fonctions globales au site
 *
 */

/*
 *
 * Ajoute les différentes propriétés aux liens
 */
jQuery(function () {
    var base = $('base')[ 0 ].href;
    // Blank sur les liens externes
    $('a[href^=http]:not([href^="' + base + '"])').prop('target', '_blank').prop('rel', 'noopener');
    // Gérer les liens retour
    $(document).on('click', 'a.retour', function () {
        if (document.referrer.indexOf(location.protocol + '//' + location.hostname) == 0) {
            history.back();
            return false;
        }
    });
    // Popup foundation
    $('body').append('<div class="reveal-modal" data-reveal id="content-popup" style="display:none"></div>');
    $('a[target=popup]:not([href*=upload])').on('click', function () {
        $('#content-popup').foundation('reveal', 'open', addtpl(this.href, 'contenu'));
        return false;
    });
    $(document).on('opened.fndtn.reveal', '[data-reveal]', function () {
        $(this).append('<a class="close-reveal-modal">&#215;</a>');
    });
    // Rajouter le base href aux liens qui n'ont qu'une ancre
    $('a[href^="#"]:not([data-smooth-scroll])').each(function (index, link) {
        link.href = window.location.pathname + $(link).attr('href');
    });
});

/**
 * Permet d'ajouter un tpl à l'url passée en paramètre
 * @param  url Url à modifier
 * @param  tpl Nom du template à ajouter
 * @returns string url modifiée
 */
global.addtpl = function (url, tpl) {
    url = url.replace(/#.*/, '');
    if (url.indexOf('tpl=') != -1)
        return url.replace(/tpl=[^&]+/, 'tpl=' + tpl);
    if (url.indexOf('?') == -1)
        return url + '?tpl=' + tpl;
    else
        return url + '&tpl=' + tpl;
}

/*
 *
 * Main
 *
 */
jQuery(function () {
    jQuery('body').on('click', '.rte a[href$="jpg"], .rte a[href$="jpeg"], .rte a[href$="png"], .rte a[href$="gif"]', function (ev) {
        var $this = jQuery(this);
        jQuery('#image-zoom-img').prop("src", $this.prop('href'));
        jQuery('#image-zoom-legend').html($this.prop('title'));
        jQuery('#image-zoom-modal').foundation('open');
        ev.preventDefault();
    });
    $('a.print, .print a').click(function (e) {
        window.open(addtpl(this.href, 'print'));
        return false;
    });
    $('a.confirm').on('click', function (ev) {
        if (!confirm($(this).prop('title') + '\n\nVous confirmez ?')) {
            ev.preventDefault();
        }
    });

    Foundation.onImagesLoaded($('.rte img:not([class*=noauto])'), function () {
        var images = $('.rte img:not([class*=noauto])');
        images.each(function (index, img) {
            img = $(img);
            alignement = img.hasClass('gauche') ? 'gauche' : ( img.hasClass('droite') ? 'droite' : ( img.hasClass('centre') ? 'centre' : '' ) );
            if (!alignement) {
                switch (img.css('float')) {
                    case 'left':
                        alignement = 'gauche';
                        break;
                    case 'right':
                        alignement = 'droite';
                        break;
                    default:
                        alignement = 'centre';
                }
                img.css('float', 'none');
            } else {
                img.removeClass(alignement);
            }
            var caption = '';
            if (img.prop('title')) {
                caption = $('<figcaption/>');
                caption.html(img.prop('title'));
            }
            var width = img.width();

            var fig = img.parents('figure');
            if (fig.length == 0) {
                fig = $('<figure class="th ' + alignement + '">');
                fig.append(img.clone());
                fig.append(caption);
                fig.css('max-width', width);
                img.replaceWith(fig);
            } else {
                $(fig).addClass('th ' + alignement).append(caption).css('max-width', width);
            }
        });
    });


});

/* Ajout aux favoris */
global.addToFavorites = function (link) {
    pageTitle = document.title;
    pageURL = document.location;
    if (typeof window.external.AddFavorite != "undefined") {
        // Internet Explorer solution
        window.external.AddFavorite(pageURL, pageTitle);
    } else if (typeof window.sidebar != "undefined" && typeof window.sidebar.addPanel != "undefined") {
        // Mozilla Firefox solution
        window.sidebar.addPanel(pageTitle, pageURL, "");
    } else {
        // Opera solution
        if (typeof ( opera ) == "object") {
            link.rel = "sidebar";
            link.title = pageTitle;
            link.url = pageURL;
            return true;
        } else {
            // The rest browsers (i.e Chrome, Safari)
            alert('Appuyer sur ' + ( navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Cmd' : 'Ctrl' ) +
                '+D Pour ajouter cette page à vos favoris.');
        }
    }
    return false;
}
